import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as moment from 'moment';

@Injectable()
export class CheckoutService {

  constructor(private http: HttpClient) { }

  generateQR(orderId, event, total): Observable<any> {
    const fechaVencimiento = moment(new Date()).add(1, 'day').format('DD/MM/yyyy');
    const bodyQr = {
      alias: orderId,
      callback: 'https://us-central1-clicket-bo.cloudfunctions.net/sipNotificationPos',
      detalleGlosa: event.name,
      monto: total,
      moneda: 'BOB',
      fechaVencimiento,
      tipoSolicitud: 'API'
    };
    return this.http.post('https://us-central1-clicket-bo.cloudfunctions.net/generateSipQR', bodyQr, {});
  }

  generateRedenlaceQR(orderId, event, monto, moneda, canal, tiempoQr): Observable<any> {
    const codSucursal = '449609';
    const nombreSucursal = 'CLICKETAPIQR'.substring(0, 50);
    const rubroComercio = '7922';
    const glosa = `Compra entradas: ${event.name}`.substring(0, 60);
    const bodyQr = {
      numeroReferencia: orderId,
      glosa: `${codSucursal}|${nombreSucursal}|${rubroComercio}|${glosa}`,
      monto,
      moneda,
      canal,
      tiempoQr,
      campoExtra: ''
    };
    return this.http.post('https://us-central1-clicket-bo.cloudfunctions.net/generateRedenlaceQrNew', bodyQr, {});
  }

  confirmCashPayment(orderId): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const body = {
      orderId
    };
    return this.http.post('https://us-central1-clicket-bo.cloudfunctions.net/soldTicketsPos', JSON.stringify(body), { headers });
  }

  confirmCashPaymentTicketsPrint(orderId): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const body = {
      orderId
    };
    return this.http.post('https://us-central1-clicket-bo.cloudfunctions.net/soldPrintedTickets', JSON.stringify(body), { headers });
  }

  deleteSaleById(saleId, userId): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const body = {
      saleId,
      userId
    };
    return this.http.post('https://us-central1-clicket-bo.cloudfunctions.net/deleteSale', JSON.stringify(body), { headers });
  }
}
